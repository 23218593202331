import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import {
    Asset,
    Button,
    Flex,
    FormControl,
    IconButton,
    TextInput,
} from '@contentful/f36-components';
import { Form } from '@contentful/f36-forms';
import { DeleteIcon } from '@contentful/f36-icons';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import {
    FormProductProps,
    Product,
    ProductTag,
    TagTypeEnums,
    VetProductCategory,
} from '../../models';
import { TagMultiselect } from '../tag-multiselect';

export const FormProduct: React.FC<FormProductProps> = (props) => {
    const { formData, onSubmitted } = props;
    const {
        control,
        register,
        handleSubmit,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formState: { errors },
        reset,
        setValue,
    } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'products',
    });
    const { createEntity, updateEntity } = useVetdeskMiddleware();

    useEffect(() => {
        reset();
        formData.map((product) => append(product));
    }, [formData]);

    const handleChangeMargin = (
        item: Product,
        index: number,
        newMargin: string
    ) => {
        const supplierPriceWithGST = Number(item.supplierPriceWithTax);
        const newPrice =
            supplierPriceWithGST +
            (supplierPriceWithGST * Number(newMargin)) / 100;
        setValue(`products.${index}.price`, newPrice.toFixed(2));

        // const newPrice = supplierPriceWithGST + Number(newMargin);
        // setValue(`products.${index}.price`, newPrice.toFixed(2));
    };

    const handleChangePrice = (
        item: Product,
        index: number,
        newPrice: string
    ) => {
        const supplierPriceWithGST = Number(item.supplierPriceWithTax);
        const newMarginPercent =
            ((Number(newPrice) - supplierPriceWithGST) / supplierPriceWithGST) *
            100;
        setValue(
            `products.${index}.marginPercent`,
            newMarginPercent.toFixed(2)
        );

        //const newMargin = Number(newPrice) - supplierPriceWithGST;
        // const newMarginPercent = Number(newPrice) - supplierPriceWithGST;
        // setValue(`products.${index}.marginPercent`, newMargin.toFixed(2));
    };

    const handleCategoryChange = (
        index: number,
        values: VetProductCategory[]
    ) => {
        setValue(`products.${index}.categories`, values);
    };

    const handlePetTagChange = (index: number, values: ProductTag[]) => {
        setValue(`products.${index}.petTypes`, values);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        try {
            await Promise.all(
                data.products.map((item: Product) =>
                    item.id
                        ? updateEntity(
                              `${ENV.middlewareBaseUrl}/api/v1/admin/product`,
                              item.id,
                              {
                                  ...item,
                                  images: null,
                              }
                          )
                        : createEntity(
                              `${ENV.middlewareBaseUrl}/api/v1/admin/product`,
                              {
                                  ...item,
                                  images: null,
                              }
                          )
                )
            );
            reset({});
            onSubmitted && onSubmitted();
        } catch (error) {
            console.warn('Oops', error);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, idx) => {
                //read Url from imageUrls before import and from images after import
                const imageUrl: string =
                    field && formData.find((d) => d.id)
                        ? (field as Product).images &&
                          (field as Product).images!.length > 0
                            ? (field as Product).images![0].url
                            : ''
                        : (field as Product).imageUrls &&
                          (field as Product).imageUrls!.length > 0
                        ? (field as Product).imageUrls![0]
                        : '';

                return (
                    <>
                        <FormControl style={{ minWidth: 100, width: 200 }}>
                            <Asset src={imageUrl} />
                        </FormControl>
                        <Flex
                            key={`${field.id}-1-${idx}`}
                            fullWidth
                            gap="spacingM"
                            justifyContent="space-between"
                        >
                            <FormControl
                                style={{ minWidth: 340, width: '50%' }}
                            >
                                <FormControl.Label>Name</FormControl.Label>
                                <TextInput
                                    isDisabled={true}
                                    {...register(
                                        `products.${idx}.name` as const
                                    )}
                                />
                            </FormControl>

                            {formData.find((d) => d.id) ? (
                                <FormControl
                                    style={{ minWidth: 340, width: '50%' }}
                                >
                                    <FormControl.Label>Brand</FormControl.Label>
                                    <TextInput
                                        isDisabled={true}
                                        value={
                                            (field as Product).brands[0]?.name
                                        }
                                    />
                                </FormControl>
                            ) : (
                                <FormControl
                                    style={{ minWidth: 340, width: '50%' }}
                                >
                                    <FormControl.Label
                                        style={{ display: 'none' }}
                                    >
                                        Placeholder
                                    </FormControl.Label>
                                </FormControl>
                            )}

                            {/* 
                            <FormControl
                                style={{ minWidth: 250, width: '40%' }}
                            >
                                <FormControl.Label>Pet Type</FormControl.Label>
                                <TagMultiselect
                                    values={(field as Product).petTypes.map(
                                        (c) => c.id
                                    )}
                                    onTagsSelect={(v) =>
                                        handlePetTagChange(idx, v)
                                    }
                                    tagType={TagTypeEnums.PETTYPES}
                                />
                            </FormControl> */}

                            {!formData.some((d) => d.id) && (
                                <FormControl style={{ width: 44 }}>
                                    <FormControl.Label
                                        style={{ display: 'none' }}
                                    >
                                        Placeholder
                                    </FormControl.Label>
                                </FormControl>
                            )}
                        </Flex>
                        <Flex
                            key={`${field.id}-1.1-${idx}`}
                            fullWidth
                            gap="spacingM"
                            justifyContent="space-between"
                        >
                            <FormControl
                                style={{ minWidth: 340, width: '50%' }}
                            >
                                <FormControl.Label>
                                    Keywords (separate by space)
                                </FormControl.Label>
                                <TextInput
                                    {...register(
                                        `products.${idx}.keywords` as const
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                style={{ minWidth: 340, width: '50%' }}
                            >
                                <FormControl.Label>
                                    Categories
                                </FormControl.Label>
                                <TagMultiselect
                                    values={(field as Product).categories.map(
                                        (c) => c.id
                                    )}
                                    onTagsSelect={(v) =>
                                        handleCategoryChange(idx, v)
                                    }
                                    tagType={TagTypeEnums.CATEGORIES}
                                />
                            </FormControl>

                            {!formData.some((d) => d.id) && (
                                <FormControl style={{ width: 44 }}>
                                    <FormControl.Label
                                        style={{ display: 'none' }}
                                    >
                                        Placeholder
                                    </FormControl.Label>
                                </FormControl>
                            )}
                        </Flex>
                        <Flex
                            key={`${field.id}-2-${idx}`}
                            fullWidth
                            gap="spacingM"
                            justifyContent="space-between"
                            style={{
                                borderBottom: '2px solid #bfbcbc',
                                paddingBottom: 8,
                                marginBottom: 16,
                            }}
                        >
                            <FormControl
                                style={{ width: '25%', minWidth: 170 }}
                            >
                                <FormControl.Label>
                                    Supplier Price (inc GST)
                                </FormControl.Label>
                                <TextInput.Group>
                                    <Button variant="secondary">$</Button>
                                    <TextInput
                                        isDisabled={true}
                                        {...register(
                                            `products.${idx}.supplierPriceWithTax` as const
                                        )}
                                    />
                                </TextInput.Group>
                            </FormControl>
                            <FormControl
                                style={{ width: '25%', minWidth: 170 }}
                            >
                                <FormControl.Label>
                                    Margin Percentage
                                </FormControl.Label>
                                <TextInput.Group>
                                    <Button variant="secondary">%</Button>
                                    <TextInput
                                        {...register(
                                            `products.${idx}.marginPercent` as const
                                        )}
                                        onChange={(e) =>
                                            handleChangeMargin(
                                                field as Product,
                                                idx,
                                                e.target.value
                                            )
                                        }
                                    />
                                </TextInput.Group>
                            </FormControl>
                            <FormControl
                                style={{ width: '25%', minWidth: 170 }}
                            >
                                <FormControl.Label>
                                    Selling Price (inc GST)
                                </FormControl.Label>
                                <TextInput.Group>
                                    <Button variant="secondary">$</Button>
                                    <TextInput
                                        {...register(
                                            `products.${idx}.price` as const
                                        )}
                                        onChange={(e) =>
                                            handleChangePrice(
                                                field as Product,
                                                idx,
                                                e.target.value
                                            )
                                        }
                                    />
                                </TextInput.Group>
                            </FormControl>

                            <FormControl
                                style={{ width: '25%', minWidth: 170 }}
                            >
                                <FormControl.Label>
                                    Discounted Price (inc GST)
                                </FormControl.Label>
                                <TextInput.Group>
                                    <Button variant="secondary">$</Button>
                                    <TextInput
                                        {...register(
                                            `products.${idx}.discountedPrice` as const
                                        )}
                                        onChange={(e) =>
                                            setValue(
                                                `products.${idx}.discountedPrice`,
                                                e.target.value
                                            )
                                        }
                                    />
                                </TextInput.Group>
                            </FormControl>
                            {!formData.some((d) => d.id) && (
                                <FormControl>
                                    <IconButton
                                        variant="transparent"
                                        size="small"
                                        style={{ paddingTop: 40 }}
                                        aria-label="remove item"
                                        onClick={() => remove(idx)}
                                        icon={<DeleteIcon />}
                                    />
                                </FormControl>
                            )}
                        </Flex>
                    </>
                );
            })}
            <Flex
                fullWidth
                justifyContent={
                    formData.some((d) => d.id) ? 'space-between' : 'flex-end'
                }
            >
                {formData.some((d) => d.id) && (
                    <Button variant="negative" onClick={() => remove(0)}>
                        Delete
                    </Button>
                )}
                <Button variant="positive" type="submit">
                    {formData.find((d) => d.id) ? 'Save' : 'Add to catalogue'}
                </Button>
            </Flex>
        </Form>
    );
};
