import React, { forwardRef, PropsWithChildren, Ref, useState } from 'react';

import { RichTextBaseProps } from './constants';

export const Button = forwardRef(
    (
        {
            style,
            active,
            ...props
        }: PropsWithChildren<
            {
                active: boolean;
            } & RichTextBaseProps
        >,
        ref: Ref<HTMLSpanElement>
    ) => {
        const [isHovered, setIsHovered] = useState(false);

        const iconStyle: React.CSSProperties = {
            width: 'fit-content',
            backgroundColor: active
                ? '#e0e0e0'
                : isHovered
                ? '#eaeaea'
                : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#222',
            padding: '4px',
            cursor: 'pointer',
            ...style,
        };

        return (
            <span
                ref={ref}
                style={iconStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...props}
            />
        );
    }
);

Button.displayName = 'Button';
