import React from 'react';
import { useSlate } from 'slate-react';

import { Button } from './button';
import { RichTextLeaf } from './constants';
import { Icon } from './icon';
import { isMarkActive, toggleMark } from './utils';

export const MarkButton = ({ type }: { type: RichTextLeaf }) => {
    const editor = useSlate();
    let format = '',
        icon = '';

    switch (type) {
        case 'bold': {
            format = 'bold';
            icon = 'format_bold';

            break;
        }
        case 'italic': {
            format = 'italic';
            icon = 'format_italic';

            break;
        }
        case 'underline': {
            format = 'underline';
            icon = 'format_underlined';

            break;
        }
        case 'code': {
            format = 'code';
            icon = 'code';

            break;
        }
        case 'strikethrough': {
            format = 'strikethrough';
            icon = 'format_strikethrough';

            break;
        }
        default: {
            break;
        }
    }

    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event: any) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};
