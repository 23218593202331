import React, { forwardRef, PropsWithChildren, Ref } from 'react';

import { RichTextBaseProps } from './constants';

export const Menu = forwardRef(
    (
        { style, ...props }: PropsWithChildren<RichTextBaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
            data-test-id="menu"
            ref={ref}
            style={{
                backgroundColor: 'rgba(245, 245, 245, 1)',
                border: '1px solid rgba(207, 217, 224, 1)',
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                ...style,
            }}
            {...props}
        />
    )
);

Menu.displayName = 'Menu';
