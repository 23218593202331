import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { Editor, Transforms } from 'slate';

import {
    Button,
    Flex,
    FormControl,
    Heading,
    Modal,
    ModalConfirm,
    Paragraph,
    Select,
    Stack,
    Switch,
    Text,
    Textarea,
    TextInput,
} from '@contentful/f36-components';
import { PlusIcon } from '@contentful/f36-icons';
import { payloadSlateToHtmlConfig, slateToHtml } from '@slate-serializers/html';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { personalisationTokens } from '../../../locations/page-auto-reminders';
import { ApiListResponse, AppointmentType } from '../../../models';
import { RichText } from '../../rich-text';
import { TableList } from '../../table-list';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/reminder`;
const API_Appointment_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/appointment/appointment-type`;

export interface UpcomingAppointmentReminder {
    id: string;
    name: string;
    vetId: string;
    vetName: string;
    appointmentTypes: AppointmentType[];
    periodFrequency: number;
    periodType: string;
    scheduleSummary: string;
    isActive: boolean;
    notificationChannel: string;
    subject: string;
    message?: string;
    messageSlate?: string | null;
}

const REMINDER_COLUMNS = [
    { label: 'Reminder name', type: 'string', field: 'name' },
    { label: 'Status', type: 'badgeActiveInactive', field: 'isActive' },
    { label: 'Scheduled', type: 'string', field: 'scheduleSummary' },
    { label: 'Message type', type: 'string', field: 'notificationChannel' },
];

export const UpcomingAppointmentReminders: React.FC = () => {
    const [editItem, setEditItem] =
        useState<UpcomingAppointmentReminder | null>(null);
    const [itemToDelete, setItemToDelete] =
        useState<UpcomingAppointmentReminder | null>(null);

    const [list, setList] = useState<
        ApiListResponse<UpcomingAppointmentReminder> | undefined
    >(undefined);

    const [appointmentTypeList, setAppointmentTypeList] = useState<
        AppointmentType[]
    >([]);

    const { runRequest } = useVetdeskMiddleware();

    const fetchList = () => {
        setList(undefined); // to display skeleton when fetching

        runRequest('GET', `${API_URL}`).then((res) => {
            const response =
                res as ApiListResponse<UpcomingAppointmentReminder>;
            setList(response);
        });
    };

    const fetchAppointmentTypes = () => {
        runRequest('GET', `${API_Appointment_URL}`).then((res) => {
            const response = res as AppointmentType[];
            setAppointmentTypeList(response);
        });
    };

    useEffect(() => {
        fetchList();
        fetchAppointmentTypes();
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        getValues,
        trigger,
        control,
    } = useForm();

    const handleUpsert = (item: UpcomingAppointmentReminder) => {
        const treatedItem = {
            ...item,
            messageSlate: item.messageSlate
                ? JSON.parse(item.messageSlate)
                : null,
        };

        // populate appointmentTypes that supports multiselect
        if (treatedItem?.appointmentTypes) {
            treatedItem.appointmentTypes = treatedItem.appointmentTypes.map(
                (x: any) => {
                    // Check if x is an object
                    if (typeof x === 'object' && x !== null) {
                        return x.id; // Extract id if x is an object
                    }
                    // Check if x is a string
                    if (typeof x === 'string') {
                        return x; // Just return the string if x is a string
                    }
                }
            );
        }

        reset(treatedItem);
        setEditItem(treatedItem);
    };

    const onModalClose = () => {
        reset({});
        setEditItem(null);
    };

    const prepareCreate = () => {
        const defaultReminder: UpcomingAppointmentReminder | null = {
            id: '',
            name: '',
            vetId: '',
            vetName: '',
            appointmentTypes: [],
            periodFrequency: 1,
            periodType: 'days',
            scheduleSummary: '',
            isActive: false,
            notificationChannel: 'email',
            subject: '',
            message: '',
            messageSlate: null,
        };
        setEditItem(defaultReminder);
        // id for create
        const formData = { ...defaultReminder, id: undefined };
        reset(formData);
    };

    const onSubmit = async (data: any) => {
        console.log('submitted data', data);
        data.appointmentTypes = (data.appointmentTypes || []).map(
            (id: string) => ({ id })
        );
        data.reminderTypeId = '3rjj9ehxtq4x526r'; // currently only supporting appointment.

        if (data.notificationChannel === 'email') {
            data.message = slateToHtml(
                data.messageSlate,
                payloadSlateToHtmlConfig
            );
            data.messageSlate = JSON.stringify(data.messageSlate);
        }

        if (data.id) {
            // edit
            await runRequest('PUT', `${API_URL}/${data.id}`, data).then(
                (res) => {
                    fetchList();
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        } else {
            // create
            await runRequest('POST', `${API_URL}`, data).then(
                (res) => {
                    fetchList();
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    const onDelete = async () => {
        if (itemToDelete) {
            await runRequest('DELETE', `${API_URL}/${itemToDelete.id}`).then(
                (res) => {
                    fetchList();
                    setItemToDelete(null);
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    watch('reminderType');

    const message = watch('message', '');

    // Handle token insertion
    const handleTokenSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const token = event.target.value;

        if (!token) return; // If no token selected, do nothing

        const textarea = document.getElementById(
            'personalizationTextarea'
        ) as HTMLTextAreaElement | null;

        if (textarea) {
            const { selectionStart, selectionEnd } = textarea;

            // Insert token at the current cursor position in the textarea
            const newText =
                message.substring(0, selectionStart) +
                token +
                message.substring(selectionEnd);

            // Update the textarea value with the new text
            setValue('message', newText);

            // Reset the dropdown after inserting the token
            event.target.value = '';
        }
    };

    const handleTokenSelectForEditor = (event: any, editor: Editor) => {
        const token = event.target.value;

        if (!token) return; // If no token selected, do nothing

        // Insert the custom text at the current selection
        Transforms.insertText(editor, token);
    };

    const renderPersonalisationTokens = (type: string, editor?: Editor) => (
        <Select
            size="small"
            style={{ minWidth: 200 }}
            onChange={
                type === 'email'
                    ? (event) => handleTokenSelectForEditor(event, editor!)
                    : handleTokenSelect
            }
        >
            <Select.Option value="">Personalisation tokens</Select.Option>
            {Object.keys(personalisationTokens).map((token) => (
                <Select.Option key={token} value={token}>
                    {
                        personalisationTokens[
                            token as keyof typeof personalisationTokens
                        ]
                    }
                </Select.Option>
            ))}
        </Select>
    );

    const renderMessageContent = (type: string) => {
        switch (type) {
            case 'email': // Email
                return (
                    <>
                        <FormControl>
                            <FormControl.Label>
                                Subject line <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        {...field}
                                        placeholder="Enter text"
                                    />
                                )}
                            />
                            {errors.subject && (
                                <Text fontColor="red500">
                                    {errors.subject.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>
                                Message <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Controller
                                name="messageSlate"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <RichText
                                        initialValue={field.value}
                                        placeholder="Enter message"
                                        onValueChange={(value) => {
                                            setValue('messageSlate', value);
                                            trigger('messageSlate');
                                        }}
                                        customButtons={[
                                            (editor) =>
                                                renderPersonalisationTokens(
                                                    type,
                                                    editor
                                                ),
                                        ]}
                                    />
                                )}
                            />
                            {errors.message && (
                                <Text fontColor="red500">
                                    {errors.message.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                    </>
                );
            case 'sms': // SMS
                return (
                    <FormControl>
                        <Flex
                            justifyContent="space-between"
                            marginBottom="spacingXs"
                        >
                            <FormControl.Label>
                                Message <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            {renderPersonalisationTokens(type)}
                        </Flex>

                        <Controller
                            name="message"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    id="personalizationTextarea"
                                    placeholder="Enter text"
                                />
                            )}
                        />
                        {errors.message && (
                            <Text fontColor="red500">
                                {errors.message.message?.toString()}
                            </Text>
                        )}
                    </FormControl>
                );
            case 'app': // APP
                return (
                    <>
                        <FormControl>
                            <FormControl.Label>
                                Subject line <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        {...field}
                                        placeholder="Enter text"
                                    />
                                )}
                            />
                            {errors.subject && (
                                <Text fontColor="red500">
                                    {errors.subject.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <Flex
                                justifyContent="space-between"
                                marginBottom="spacingXs"
                            >
                                <FormControl.Label>
                                    Message <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                {renderPersonalisationTokens(type)}
                            </Flex>
                            <Controller
                                name="message"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <Textarea
                                        {...field}
                                        id="personalizationTextarea"
                                        placeholder="Enter text"
                                    />
                                )}
                            />
                            {errors.message && (
                                <Text fontColor="red500">
                                    {errors.message.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                    </>
                );
            default:
                return <></>;
        }
    };

    const renderEditModel = () => {
        return (
            <Modal
                size="800px"
                onClose={onModalClose}
                isShown={editItem != null}
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title={
                                getValues('id')
                                    ? 'Edit reminder'
                                    : 'Create reminder'
                            }
                            onClose={onModalClose}
                        />
                        <Modal.Content>
                            <FormControl>
                                <FormControl.Label>Status</FormControl.Label>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                        >
                                            {field.value == true ? 'On' : 'Off'}
                                        </Switch>
                                    )}
                                />
                                <Paragraph>
                                    Toggle on/off to activate or deactivate
                                    reminders without losing any information.
                                </Paragraph>
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Reminder name
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            {...field}
                                            placeholder="Enter text"
                                        />
                                    )}
                                />
                                {errors.name && (
                                    <Text fontColor="red500">
                                        {errors.name.message?.toString()}
                                    </Text>
                                )}
                                <Paragraph>
                                    This will be shown in the table to help you
                                    distinguish.
                                </Paragraph>
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Appointment types{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    control={control}
                                    name="appointmentTypes"
                                    render={({
                                        field: { onChange, value },
                                    }) => {
                                        const options =
                                            appointmentTypeList?.map((x) => ({
                                                value: x.id,
                                                label: x.name,
                                            }));
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <ReactSelect
                                                    value={options.filter((c) =>
                                                        value?.includes(c.value)
                                                    )}
                                                    onChange={(val) => {
                                                        const values = val.map(
                                                            (c) => c.value
                                                        );
                                                        onChange(values);
                                                    }}
                                                    options={options}
                                                    isMulti
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                {errors.appointmentTypes && (
                                    <Text fontColor="red500">
                                        {errors.appointmentTypes.message?.toString()}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Channel type{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="notificationChannel"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            style={{ minWidth: 100 }}
                                        >
                                            <Select.Option value="email">
                                                Email
                                            </Select.Option>
                                            <Select.Option value="sms">
                                                SMS
                                            </Select.Option>
                                            <Select.Option value="app">
                                                App notification
                                            </Select.Option>
                                        </Select>
                                    )}
                                />
                                <Paragraph>
                                    This will be shown in the table to help you
                                    distinguish.
                                </Paragraph>
                            </FormControl>
                            {getValues('notificationChannel') &&
                                renderMessageContent(
                                    getValues('notificationChannel')
                                )}
                            <FormControl>
                                <FormControl.Label>
                                    How prior would you like to send the
                                    reminder? <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Stack>
                                    <Controller
                                        name="periodFrequency"
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                style={{ minWidth: 100 }}
                                            >
                                                <Select.Option value="1">
                                                    1
                                                </Select.Option>
                                                <Select.Option value="2">
                                                    2
                                                </Select.Option>
                                                <Select.Option value="3">
                                                    3
                                                </Select.Option>
                                            </Select>
                                        )}
                                    />

                                    <Controller
                                        name="periodType"
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                style={{ minWidth: 100 }}
                                            >
                                                <Select.Option value="hours">
                                                    Hours
                                                </Select.Option>
                                                <Select.Option value="days">
                                                    Days
                                                </Select.Option>
                                                <Select.Option value="weeks">
                                                    Weeks
                                                </Select.Option>
                                                <Select.Option value="months">
                                                    Months
                                                </Select.Option>
                                            </Select>
                                        )}
                                    />
                                </Stack>
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Stack>
                                <Button
                                    size="small"
                                    variant="negative"
                                    onClick={() => {
                                        setItemToDelete(editItem);
                                    }}
                                >
                                    Delete
                                </Button>

                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={onModalClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    onClick={() => handleSubmit(onSubmit)()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Modal.Controls>
                        <ModalConfirm
                            title="Delete reminder"
                            intent="negative"
                            isShown={itemToDelete != null}
                            onCancel={() => {
                                setItemToDelete(null);
                            }}
                            onConfirm={onDelete}
                            confirmLabel="Delete"
                        >
                            <Text>
                                Do you really want to delete this reminder?
                            </Text>
                        </ModalConfirm>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <div>
                    <Heading>Upcoming appointments</Heading>
                    <Paragraph>Explanation about this reminder.</Paragraph>
                </div>

                <Button
                    startIcon={<PlusIcon />}
                    variant="primary"
                    size="small"
                    onClick={prepareCreate}
                >
                    Add new reminder
                </Button>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TableList<UpcomingAppointmentReminder>
                    columns={REMINDER_COLUMNS}
                    items={list?.items}
                    onEdit={handleUpsert}
                />
            </Flex>
            {renderEditModel()}
        </>
    );
};
