import React, { Fragment, useCallback, useMemo } from 'react';
import { createEditor, Descendant, Editor } from 'slate';
import { withHistory } from 'slate-history';
import {
    Editable,
    RenderElementProps,
    RenderLeafProps,
    Slate,
    withReact,
} from 'slate-react';

import {
    BlockButton,
    Element,
    Leaf,
    MarkButton,
    RichTextElement,
    richTextInitialValue,
    RichTextLeaf,
    Toolbar,
    withLinks,
} from './components';

export interface RichTextProps {
    initialValue?: Descendant[];
    placeholder?: string;
    onValueChange?: ((value: Descendant[]) => void) | undefined;
    elements?: RichTextElement[];
    leaves?: RichTextLeaf[];
    customButtons?: ((editor: Editor) => React.ReactNode)[];
}

export const RichText: React.FC<RichTextProps> = ({
    initialValue,
    placeholder,
    onValueChange,
    elements = ['ol', 'ul', 'link'],
    leaves = ['bold', 'italic', 'underline'],
    customButtons = [],
}) => {
    const renderElement = useCallback(
        (props: RenderElementProps) => <Element {...props} />,
        []
    );
    const renderLeaf = useCallback(
        (props: RenderLeafProps) => <Leaf {...props} />,
        []
    );
    const editor = useMemo(
        () => withHistory(withLinks(withReact(createEditor()))),
        []
    );

    return (
        <Slate
            editor={editor}
            initialValue={initialValue || richTextInitialValue}
            onValueChange={onValueChange}
        >
            <Toolbar>
                {leaves.map((leave) => (
                    <MarkButton key={leave} type={leave} />
                ))}

                {elements.map((element) => {
                    if (element === 'textAlign') {
                        return (
                            <Fragment key={element}>
                                <BlockButton type="left" />
                                <BlockButton type="center" />
                                <BlockButton type="right" />
                                <BlockButton type="justify" />
                            </Fragment>
                        );
                    }

                    return <BlockButton key={element} type={element} />;
                })}

                {customButtons.map((renderButton, index) => (
                    <Fragment key={index}>{renderButton(editor)}</Fragment>
                ))}
            </Toolbar>

            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                style={{
                    padding: '8px',
                    border: '1px solid rgba(207, 217, 224, 1)',
                    borderTopWidth: '0px',
                    borderBottomLeftRadius: '6px',
                    borderBottomRightRadius: '6px',
                }}
                placeholder={placeholder}
                renderPlaceholder={({ children, attributes }) => (
                    <span
                        {...attributes}
                        style={{
                            width: '100%',
                            maxWidth: '100%',
                            opacity: 0.33,
                            userSelect: 'none',
                            textDecoration: 'none',
                            pointerEvents: 'none',
                        }}
                    >
                        {children}
                    </span>
                )}
            />
        </Slate>
    );
};
