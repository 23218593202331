import React from 'react';
import { RenderElementProps } from 'slate-react';

import { Link } from './link';

export const Element = ({
    attributes,
    children,
    element,
}: RenderElementProps & { element: any }) => {
    const style = { textAlign: element.align };

    switch (element.type) {
        case 'h1':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'h2':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'h3':
            return (
                <h3 style={style} {...attributes}>
                    {children}
                </h3>
            );
        case 'h4':
            return (
                <h4 style={style} {...attributes}>
                    {children}
                </h4>
            );
        case 'h5':
            return (
                <h5 style={style} {...attributes}>
                    {children}
                </h5>
            );
        case 'h6':
            return (
                <h6 style={style} {...attributes}>
                    {children}
                </h6>
            );
        case 'ul':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'ol':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        case 'li':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'link':
            return (
                <Link attributes={attributes} element={element}>
                    {children}
                </Link>
            );
        case 'blockquote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};
