import { Descendant } from 'slate';

export const LIST_TYPES = ['ul', 'ol'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export type RichTextElement =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'ol'
    | 'ul'
    | 'link'
    | 'blockquote' // not supported in FE yet
    | 'textAlign'; // not supported in FE yet
export type Alignment = 'left' | 'center' | 'right' | 'justify';

export type RichTextLeaf =
    | 'bold'
    | 'italic'
    | 'underline'
    | 'code' // not supported in FE yet
    | 'strikethrough'; // not supported in FE yet

export interface RichTextBaseProps {
    className: string;
    style: React.CSSProperties;
    [key: string]: unknown;
}

export type LinkElement = { type: 'link'; url: string; children: Descendant[] };

export const richTextInitialValue: any = [
    {
        type: 'p',
        children: [{ text: '' }],
    },
];
