import 'material-icons/iconfont/material-icons.css';

import React, { forwardRef, PropsWithChildren, Ref } from 'react';

import { RichTextBaseProps } from './constants';

export const Icon = forwardRef(
    (
        { className, style, ...props }: PropsWithChildren<RichTextBaseProps>,
        ref: Ref<HTMLSpanElement>
    ) => (
        <span
            ref={ref}
            className={`material-icons ${className || ''}`}
            style={{ fontSize: '18px', ...style }}
            {...props}
        />
    )
);

Icon.displayName = 'Icon';
