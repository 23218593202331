import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';

import {
    Badge,
    Button,
    Card,
    DragHandle,
    Flex,
    FormControl,
    Heading,
    Modal,
    Paragraph,
    Skeleton,
    Stack,
    Switch,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { EditIcon, ToggleIcon } from '@contentful/f36-icons';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { AppointmentType, Calendar, Species } from '../../../models';

const API_URL_SYNC = `${ENV.middlewareBaseUrl}/api/v2/admin/pms/sync/calendar`;
const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/calendar`;
const API_Appointment_Type_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/appointment/appointment-type`;
const API_Species_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/species`;

export const CalendarList: React.FC = () => {
    const styles = {
        card: css({
            // This lets us change z-index when dragging
            position: 'relative',
        }),
        dragHandle: css({
            alignSelf: 'stretch',
        }),
    };
    const [editItem, setEditItem] = useState<Calendar | null>(null);

    const [draggableItems, setDraggableItems] = useState<
        Calendar[] | undefined
    >(undefined);
    const [appointmentTypeList, setAppointmentTypeList] = useState<
        AppointmentType[]
    >([]);
    const [speciesList, setSpeciesList] = useState<Species[]>([]);

    const { runRequest } = useVetdeskMiddleware();

    const fetchList = () => {
        setDraggableItems(undefined); // to display skeleton when fetching

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as Calendar[];
            setDraggableItems(response);
        });
    };

    const fetchAppointmentTypes = () => {
        runRequest('GET', `${API_Appointment_Type_URL}`).then((res) => {
            const response = res as AppointmentType[];
            setAppointmentTypeList(response);
        });
    };

    const fetchSpecies = () => {
        runRequest('GET', `${API_Species_URL}`).then((res) => {
            const response = res as Species[];
            setSpeciesList(response);
        });
    };

    const syncFromPMS = () => {
        setDraggableItems(undefined); // to display skeleton when fetching

        runRequest('POST', `${API_URL_SYNC}`).then((res) => {
            const response = res as Calendar[];
            setDraggableItems(response);
        });
    };

    useEffect(() => {
        fetchList();
        fetchAppointmentTypes();
        fetchSpecies();
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        control,
    } = useForm();

    const handleUpsert = (item?: any) => {
        reset(item);
        setEditItem(item);
    };

    const onModalClose = () => {
        reset({});
        setEditItem(null);
    };

    const onSubmit = async (data: any) => {
        console.log('submitted data', data);

        if (data.id) {
            // edit
            await runRequest('PUT', `${API_URL}/${data.id}`, data).then(
                (res) => {
                    fetchList();
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    function SortableCard({ id, value }: any) {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            active,
        } = useSortable({
            id,
        });
        const zIndex = active && active.id === id ? 1 : 0;
        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            zIndex,
        };

        return (
            <Card
                className={styles.card}
                dragHandleRender={() => (
                    <DragHandle
                        as="button"
                        className={styles.dragHandle}
                        label="Move card"
                        {...attributes}
                        {...listeners}
                    />
                )}
                padding="none"
                withDragHandle
                ref={setNodeRef}
                style={style}
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    padding="spacingS"
                >
                    <span>
                        <b>{value.name}</b>
                    </span>{' '}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap="spacingXl"
                    >
                        <span style={{ marginRight: '0px' }}>
                            PMS Name: {value.pmsName}
                        </span>
                        {value.isActive ? (
                            <Badge variant="positive">ACTIVE</Badge>
                        ) : (
                            <Badge variant="negative">INACTIVE</Badge>
                        )}
                        <Button
                            onClick={() => {
                                reset(value); // update the useForm with the selected item
                                setEditItem(value);
                            }}
                            startIcon={<EditIcon />}
                            size="small"
                        >
                            Edit
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        );
    }

    const updateSort = async (data: Calendar[]) => {
        await runRequest('PUT', API_URL, data).then(
            (res) => {
                fetchList();
            },
            (error) => console.warn('Oops', error)
        );
    };

    const handleDragEnd = (event: { active: any; over: any }) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setDraggableItems((items) => {
                if (items) {
                    const oldIndex = items.findIndex(
                        (item) => item.id === active.id
                    );
                    const newIndex = items.findIndex(
                        (item) => item.id === over.id
                    );
                    const updatedItems = arrayMove(items, oldIndex, newIndex);
                    updateSort(updatedItems);
                    return updatedItems;
                }
            });
        }
    };

    const renderEditModel = () => {
        return (
            <Modal
                size="800px"
                onClose={onModalClose}
                isShown={editItem != null}
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title={getValues('id') ? 'Edit' : 'Create'}
                            onClose={onModalClose}
                        />
                        <Modal.Content>
                            <FormControl>
                                <FormControl.Label>Status</FormControl.Label>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                        >
                                            {field.value == true ? 'On' : 'Off'}
                                        </Switch>
                                    )}
                                />
                                <Paragraph>
                                    Toggle on/off to activate or deactivate
                                    reminders without losing any information.
                                </Paragraph>
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Display name to clients
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            {...field}
                                            placeholder="Enter text"
                                        />
                                    )}
                                />
                                {errors.name && (
                                    <Text fontColor="red500">
                                        {errors.name.message?.toString()}
                                    </Text>
                                )}
                                <Controller
                                    name="pmsName"
                                    control={control}
                                    render={({ field }) => (
                                        <Paragraph>
                                            PMS name: {field.value}
                                        </Paragraph>
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Appointment types{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    control={control}
                                    name="appointmentTypes"
                                    render={({
                                        field: { onChange, value },
                                    }) => {
                                        const options =
                                            appointmentTypeList?.map((x) => ({
                                                value: x.id,
                                                label: x.name,
                                            }));
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <ReactSelect
                                                    value={options?.filter(
                                                        (option) =>
                                                            (value || []).some(
                                                                (x: any) =>
                                                                    x.id ===
                                                                    option.value
                                                            )
                                                    )}
                                                    onChange={(val) => {
                                                        const values = val.map(
                                                            (c) => ({
                                                                id: c.value,
                                                            })
                                                        );
                                                        onChange(values);
                                                    }}
                                                    options={options}
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    isMulti
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                {errors.appointmentTypes && (
                                    <Text fontColor="red500">
                                        {errors.appointmentTypes.message?.toString()}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Species types{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    control={control}
                                    name="species"
                                    render={({
                                        field: { onChange, value },
                                    }) => {
                                        const options = speciesList?.map(
                                            (x) => ({
                                                value: x.id,
                                                label: x.name,
                                            })
                                        );
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <ReactSelect
                                                    value={options?.filter(
                                                        (option) =>
                                                            (value || []).some(
                                                                (x: any) =>
                                                                    x.id ===
                                                                    option.value
                                                            )
                                                    )}
                                                    onChange={(val) => {
                                                        const values = val.map(
                                                            (c) => ({
                                                                id: c.value,
                                                            })
                                                        );
                                                        onChange(values);
                                                    }}
                                                    options={options}
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    isMulti
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                {errors.appointmentTypes && (
                                    <Text fontColor="red500">
                                        {errors.appointmentTypes.message?.toString()}
                                    </Text>
                                )}
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Stack>
                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={onModalClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    onClick={() => handleSubmit(onSubmit)()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Modal.Controls>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <div>
                    <Heading>Calendar</Heading>
                    {/* <Paragraph></Paragraph> */}
                </div>
                <Button
                    startIcon={<ToggleIcon />}
                    variant="secondary"
                    size="small"
                    onClick={syncFromPMS}
                >
                    Refresh from PMS
                </Button>
            </Stack>
            {draggableItems ? (
                <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={draggableItems.map((x) => x.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Flex flexDirection="column" gap="spacingS">
                            {draggableItems.map((item) => (
                                <SortableCard
                                    key={item.id}
                                    id={item.id}
                                    value={item}
                                />
                            ))}
                        </Flex>
                    </SortableContext>
                </DndContext>
            ) : (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            )}
            {renderEditModel()}
        </>
    );
};
