import React from 'react';
import { useSlate } from 'slate-react';

import { Button } from './button';
import { Alignment, RichTextElement, TEXT_ALIGN_TYPES } from './constants';
import { Icon } from './icon';
import { insertLink, isBlockActive, toggleBlock } from './utils';

export const BlockButton = ({
    type,
}: {
    type: RichTextElement | Alignment;
}) => {
    const editor = useSlate();
    let format = '',
        icon = '';

    switch (type) {
        case 'h1': {
            format = 'h1';
            icon = 'looks_one';

            break;
        }
        case 'h2': {
            format = 'h2';
            icon = 'looks_two';

            break;
        }
        case 'h3': {
            format = 'h3';
            icon = 'looks_3';

            break;
        }
        case 'h4': {
            format = 'h4';
            icon = 'looks_4';

            break;
        }
        case 'h5': {
            format = 'h5';
            icon = 'looks_5';

            break;
        }
        case 'h6': {
            format = 'h6';
            icon = 'looks_6';

            break;
        }
        case 'ol': {
            format = 'ol';
            icon = 'format_list_numbered';

            break;
        }
        case 'ul': {
            format = 'ul';
            icon = 'format_list_bulleted';

            break;
        }
        case 'link': {
            format = 'link';
            icon = 'link';

            break;
        }
        case 'blockquote': {
            format = 'blockquote';
            icon = 'format_quote';

            break;
        }
        case 'left': {
            format = 'left';
            icon = 'format_align_left';

            break;
        }
        case 'center': {
            format = 'center';
            icon = 'format_align_center';

            break;
        }
        case 'right': {
            format = 'right';
            icon = 'format_align_right';

            break;
        }
        case 'justify': {
            format = 'justify';
            icon = 'format_align_justify';

            break;
        }
        default: {
            break;
        }
    }

    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={(event: any) => {
                event.preventDefault();

                if (type !== 'link') {
                    toggleBlock(editor, format);
                } else {
                    insertLink(editor);
                }
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};
