import React, { useMemo } from 'react';
import {
    RenderElementProps,
    useFocused,
    useSelected,
    useSlateStatic,
} from 'slate-react';

import { Icon } from './icon';
import { unwrapLink } from './utils';

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];

export const Link = ({
    attributes,
    children,
    element,
}: RenderElementProps & { element: any }) => {
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();

    const safeUrl = useMemo(() => {
        let parsedUrl: any = null;
        try {
            parsedUrl = new URL(element.url);
            // eslint-disable-next-line no-empty
        } catch {}
        if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
            return parsedUrl.href;
        }
        return 'about:blank';
    }, [element.url]);

    return (
        <span
            style={{
                display: 'inline',
                position: 'relative',
            }}
        >
            <a {...attributes} href={safeUrl}>
                {children}
            </a>

            {selected && focused && (
                <div
                    contentEditable={false}
                    style={{
                        position: 'absolute',
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        padding: '6px 10px',
                        borderRadius: '6px',
                        border: '1px solid lightgray',
                    }}
                >
                    <Icon>link</Icon>

                    <a
                        style={{
                            paddingRight: '5px',
                            borderRight: '1px solid lightgrey',
                            marginLeft: '5px',
                            marginRight: '5px',
                        }}
                        href={safeUrl}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {safeUrl}
                    </a>

                    <Icon
                        style={{ cursor: 'pointer' }}
                        onClick={() => unwrapLink(editor)}
                    >
                        link_off
                    </Icon>
                </div>
            )}
        </span>
    );
};
