import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    Button,
    Flex,
    Form,
    FormControl,
    Heading,
    Paragraph,
    Skeleton,
    Switch,
    Text,
} from '@contentful/f36-components';
import { payloadSlateToHtmlConfig, slateToHtml } from '@slate-serializers/html';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { AppointmentMessage } from '../../../models';
import { RichText } from '../../rich-text';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/appointment/message`;

const EmergencyMessageTypeId = 2;

export const EmergencyInformation: React.FC = () => {
    // Data domains
    const { runRequest } = useVetdeskMiddleware();

    // Component states
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState<AppointmentMessage[]>();

    // Form setup
    const {
        handleSubmit,
        formState: { errors },
        reset,
        register,
        setValue,
        trigger,
        control,
        watch,
    } = useForm();

    // Component defaults
    const isActive = watch('isActive');

    useEffect(() => {
        register('isActive');
        fetchMessages();
    }, []);

    const fetchMessages = () => {
        setIsLoading(true);
        setMessages(undefined);

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as AppointmentMessage[];

            const emergency = response.find(
                (x) => x.messageTypeId == EmergencyMessageTypeId
            );
            if (emergency) {
                reset({
                    ...emergency,
                    messageSlate: emergency.messageSlate
                        ? JSON.parse(emergency.messageSlate)
                        : null,
                });
                setMessages(response);
            } else {
                const defaultMessage: AppointmentMessage = {
                    title: '',
                    messageSlate: null,
                    isActive: true,
                    messageTypeId: EmergencyMessageTypeId,
                };

                reset(defaultMessage); // populate default

                response.push(defaultMessage);
                setMessages(response);
            }
            setIsLoading(false);
        });
    };

    const renderFields = () => {
        register('message', {
            required: 'This is required field',
        });
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Flex
                    flexDirection="column"
                    gap="spacingS"
                    justifyContent="center"
                >
                    <FormControl>
                        <FormControl.Label>
                            Emergency Information
                            <Text fontColor="red500">*</Text>
                        </FormControl.Label>
                        <Controller
                            name="messageSlate"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <RichText
                                    initialValue={field.value}
                                    placeholder="Enter notes, terms and conditions, or special instruction here."
                                    onValueChange={(value) => {
                                        setValue('messageSlate', value);
                                        trigger('messageSlate');
                                    }}
                                />
                            )}
                        />
                        {errors.message && (
                            <Text fontColor="red500">
                                {errors.message.message?.toString()}
                            </Text>
                        )}
                    </FormControl>
                </Flex>
            </Form>
        );
    };

    const onSubmit = async (data: any) => {
        const updatedMessages = messages?.map((msg) => {
            if (msg.messageTypeId === EmergencyMessageTypeId) {
                const serializedHtml = slateToHtml(
                    data.messageSlate,
                    payloadSlateToHtmlConfig
                );

                return {
                    ...data,
                    message: serializedHtml,
                    messageSlate: JSON.stringify(data.messageSlate),
                };
            }
            return msg;
        });

        await runRequest('PUT', API_URL, updatedMessages).then(
            (res) => {
                fetchMessages();
            },
            (error) => console.warn('Oops', error)
        );
    };

    return !isLoading ? (
        <>
            <Heading>Enable emergency information</Heading>
            <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                    <Switch isChecked={field.value} onChange={field.onChange}>
                        {field.value == true ? 'On' : 'Off'}
                    </Switch>
                )}
            />
            <Paragraph style={{ marginTop: 10 }}>
                Turn this on to display emergency contact information at the
                start of the booking process.{' '}
            </Paragraph>
            {isActive && renderFields()}
            <Button
                size="small"
                variant="primary"
                onClick={() => handleSubmit(onSubmit)()}
            >
                Save
            </Button>
        </>
    ) : (
        <Skeleton.Container>
            <Skeleton.BodyText numberOfLines={4} />
        </Skeleton.Container>
    );
};
