import { Editor, Element as SlateElement, Range, Transforms } from 'slate';

import { LinkElement, LIST_TYPES, TEXT_ALIGN_TYPES } from './constants';

export const toggleBlock = (editor: Editor, format: string) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );
    const isList = LIST_TYPES.includes(format);
    let newProperties: any;

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes((n as any).type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    });

    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        };
    } else {
        newProperties = {
            type: isActive ? 'p' : isList ? 'li' : format,
        };
    }

    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

export const toggleMark = (editor: Editor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

export const isBlockActive = (
    editor: Editor,
    format: any,
    blockType = 'type'
) => {
    const { selection } = editor;

    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n as any)[blockType] === format,
        })
    );

    return !!match;
};

export const isMarkActive = (editor: Editor, format: string) => {
    const marks: any = Editor.marks(editor);

    return marks ? marks[format] === true : false;
};

export const withLinks = (editor: any) => {
    const { isInline } = editor;

    editor.isInline = (element: any) =>
        element.type === 'link' ? true : isInline(element);

    return editor;
};

export const isLinkActive = (editor: Editor) => {
    const [link] = Array.from(
        Editor.nodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n as any).type === 'link',
        })
    );

    return !!link;
};

export const unwrapLink = (editor: Editor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            (n as any).type === 'link',
    });
    // Reset any leftover properties on the text nodes
    Transforms.setNodes(
        editor,
        { url: undefined } as any, // Clear the `url` property or any other custom attributes
        { match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) }
    );
};

export const wrapLink = (editor: Editor, url: string) => {
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

export const insertLink = (editor: Editor) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    if (editor.selection) {
        const url = prompt('Enter a URL');
        if (!url) return;
        wrapLink(editor, url);
    }
};
