import React from 'react';
import { RenderLeafProps } from 'slate-react';

export const Leaf = ({
    attributes,
    children,
    leaf,
}: RenderLeafProps & { leaf: any }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    return (
        <span
            {...attributes}
            style={
                leaf.strikethrough
                    ? { textDecoration: 'line-through' }
                    : undefined
            }
        >
            {children}
        </span>
    );
};
