import React, { forwardRef, PropsWithChildren, Ref } from 'react';

import { RichTextBaseProps } from './constants';
import { Menu } from './menu';

export const Toolbar = forwardRef(
    (
        { ...props }: PropsWithChildren<RichTextBaseProps>,
        ref: Ref<HTMLDivElement>
    ) => <Menu ref={ref} {...props} />
);

Toolbar.displayName = 'Toolbar';
